import ThemeModel from '@Api/models/ThemeModel';
import UserModel from '@Api/models/UserModel';

import { ProfileResponse } from '@Api-generated';

export enum KeyboardLayout {
  Qwerty = 'Qwerty',
  Neo = 'Neo',
  Colemak = 'Colemak',
  Dvorak = 'Dvorak',
  Maltron = 'Maltron',
  Turkish = 'Turkish',
  Workman = 'Workman',
  Azerty = 'Azerty',
  Stenotype = 'Stenotype',
}

export default class ProfileModel implements ProfileResponse {
  public id: string;
  public createdAt: string;
  public bio: string;
  public keyboard: string;
  public keyboardLayout: KeyboardLayout;
  public user: UserModel;
  public gravatarEmail: string | null;
  public wordsTyped?: number;
  public competitionsWon?: number;
  public competitionsParticipated?: number;
  public legacyId?: number | null;
  public updatedAt?: string;
  public modMessage?: string | null;
  public userModMessage?: string | null;
  public theme?: ThemeModel;

  constructor(
    id: string,
    createdAt: string,
    bio: string,
    keyboard: string,
    keyboardLayout: KeyboardLayout,
    user: UserModel,
    gravatarEmail: string | null,
    wordsTyped?: number,
    competitionsWon?: number,
    competitionsParticipated?: number,
    legacyId?: number | null,
    updatedAt?: string,
    modMessage?: string | null,
    userModMessage?: string | null,
    theme?: ThemeModel
  ) {
    this.id = id;
    this.bio = bio;
    this.keyboard = keyboard;
    this.keyboardLayout = keyboardLayout;
    this.user = user;
    this.gravatarEmail = gravatarEmail;
    this.wordsTyped = wordsTyped;
    this.competitionsWon = competitionsWon;
    this.competitionsParticipated = competitionsParticipated;
    this.legacyId = legacyId;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.modMessage = modMessage;
    this.userModMessage = userModMessage;
    this.theme = theme;
  }

  public static hydrateFromApi(profileResponse: ProfileResponse): ProfileModel {
    const userModel = UserModel.hydrateFromApi(profileResponse.user);

    const keyboardLayout = profileResponse.keyboardLayout as KeyboardLayout;

    return new ProfileModel(
      profileResponse.id,
      profileResponse.createdAt,
      profileResponse.bio,
      profileResponse.keyboard,
      keyboardLayout,
      userModel,
      profileResponse.gravatarEmail,
      profileResponse.wordsTyped,
      profileResponse.competitionsWon,
      profileResponse.competitionsParticipated,
      profileResponse.legacyId,
      profileResponse.updatedAt,
      profileResponse.modMessage,
      profileResponse.userModMessage,
      profileResponse.theme ? ThemeModel.hydrateFromApi(profileResponse.theme) : undefined
    );
  }
}
